const initialState = () => ({
    loading: false,
    loadingTasks: [],
    loadingPercentage: 0
});

const state = initialState();

const getters = {
    pageLoading (state) {
        if (state.loadingTasks.length > 0) {
            return true
        } else {
            return false
        }
    },
    pageLoadingTasks (state) {
        return state.loadingTasks
    },
    pageLoadingPercentage (state) {
        return state.loadingPercentage
    },
}

const mutations = {
    updateLoadingTasks (state, loading) {
        if (loading === true) {
            state.loadingTasks.push("task")
        } else {
            state.loadingTasks.pop()
        }  
    },
    clearLoadingTasks (state) {
        state.loadingTasks = [] 
    },
    updateLoadingPercentage (state, loadingPercentage) {
        state.loadingPercentage = loadingPercentage
    },
}

const actions = {
    updateLoading ({ commit }, payload) {
        commit('updateLoadingTasks', payload)
    },
    clearLoadingTasks ({ commit }) {
        commit('clearLoadingTasks')
    },
    updateLoadingPercentage ({ commit }, payload) {
        commit('updateLoadingPercentage', payload)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
