import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Countries from "@/assets/jsons/geo/countries.json"
// import * as Sentry from "@sentry/vue";

// font-awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faGlobe, faEye, faEyeSlash, faTimes, faChevronRight, faChartArea, faFileUpload, faChartBar, faGavel, faFileContract, faUserCog, faFileInvoice, faBox, faUsers, faPlayCircle,
   faArrowCircleUp, faChevronDown, faShoppingBasket, faBell, faFolderPlus, faFolderMinus, faDotCircle, faSearch, faFileSignature, faPen, faTrashAlt, faPenAlt, faUser, faCaretSquareRight,
   faSort, faSortDown, faSortUp, faPencilAlt, faInbox, faFileAlt, faCheck, faShieldAlt, faHammer, faAngleDown,
   faArrowLeft, faChevronLeft, faFileDownload, faPrint, faDownload, faUserAlt, faUserCircle, faCircle, faCartPlus, faChevronUp,
   faBook, faReceipt, faLock, faCircleNotch, faSpinner, faClone
  } from '@fortawesome/free-solid-svg-icons'
  import { faLine } from '@fortawesome/free-brands-svg-icons'
import {  } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faGlobe, faEye, faEyeSlash, faTimes, faChevronRight, faChartArea, faFileUpload, faChartBar, faGavel, faFileContract, faUserCog, faFileInvoice, faBox, faUsers, faPlayCircle,
  faArrowCircleUp, faChevronDown, faShoppingBasket, faBell, faFolderPlus, faFolderMinus, faDotCircle, faSearch, faFileSignature, faPen, faTrashAlt, faPenAlt, faUser, faCaretSquareRight,
  faSort, faSortDown, faSortUp, faPencilAlt, faInbox, faFileAlt, faCheck, faShieldAlt, faHammer, faAngleDown,
  faArrowLeft, faChevronLeft, faFileDownload, faPrint, faDownload, faUserAlt, faUserCircle, faCircle, faCartPlus,
  faChevronUp, faBook, faReceipt, faLock, faCircleNotch, faSpinner, faLine, faClone)

Vue.component('font-awesome-icon', FontAwesomeIcon)

// vue table
import {ClientTable, ServerTable} from 'vue-tables-3';
Vue.use(ClientTable);
Vue.use(ServerTable);

// Vue Pagination
import Pagination from 'vue-pagination-2';
Vue.component('pagination', Pagination);

// Vue PDF
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
Vue.component('vue-pdf-embed', VuePdfEmbed);

// Vue axios
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

// Vue form wizard
import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
Vue.use(VueFormWizard)

// Vue sweet alert
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

// Vue country select
import vueCountryRegionSelect from 'vue-country-region-select'
Vue.use(vueCountryRegionSelect)

// Vue  TinyMCE
import Editor from '@tinymce/tinymce-vue';
// var Editor = require('@tinymce/tinymce-vue').default;
Vue.component('editor', Editor)

// Vue Draggable
import draggable from 'vuedraggable'
Vue.component('draggable', draggable)

// Vue Apex Charts
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

// toast
import Swal from 'sweetalert2'
const toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
  });
window.toast = toast;

// debounce
// import vueDebounce from 'vue-debounce'
// Vue.use(vueDebounce)


// Vue Select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
Vue.component('v-select', vSelect)

// Vue Charts
import VueCharts from 'vue-chartjs'
Vue.use(VueCharts);

// Vue tab component
import {Tabs, Tab} from 'vue-tabs-component';
Vue.component('tabs', Tabs);
Vue.component('tab', Tab);

// Timeago
import VueTimeago from 'vue-timeago'
Vue.use(VueTimeago, {
  name: 'Timeago', // Component name, `Timeago` by default
  locale: 'en', // Default locale
  // We use `date-fns` under the hood
  // So you can use all locales from it
  locales: {
    'zh-CN': require('date-fns/locale/zh_cn'),
    ja: require('date-fns/locale/ja')
  }
})


//ClickOutside
import vClickOutside from 'v-click-outside'
Vue.use(vClickOutside)

// Vue slick carousel
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
Vue.component('VueSlickCarousel', VueSlickCarousel);

// Vue count down
import vueAwesomeCountdown from 'vue-awesome-countdown'
Vue.use(vueAwesomeCountdown, 'vac')

// Vue telephone input
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
Vue.use(VueTelInput);

// Vue translation
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

// Vue country flags
import CountryFlag from 'vue-country-flag'
Vue.component('country-flag', CountryFlag)

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

function getStartingLocale() {
  if (localStorage.getItem('last-locale')) {
      return localStorage.getItem('last-locale')
  }
  return "en"
}

const i18n = new VueI18n({
  locale: getStartingLocale(),
  fallbackLocale: 'en',
  silentTranslationWarn: true,
  messages: loadLocaleMessages()
});

// Prevent parent from scrolling
Vue.use(require('vue-prevent-parent-scroll'))

// Format currency
Vue.filter('toCurrency', function (value, currency) {
  // if (typeof value !== "number") {
  //     return value;
  // }
  var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2
  });
  return formatter.format(value);
});

// Format currency
Vue.filter('toMoney', function (value) {

  var formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2
  });
  return formatter.format(value);
});

// Format Date
import moment from 'moment'
Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD-MM-YYYY')
  }
});

Vue.filter('formatDateArchive', function(value) {
  if (value) {
    return moment(String(value)).add(7, 'y').format('DD-MM-YYYY')
  }
});

Vue.filter('formatDateTime', function(value) {
  if (value) {
    return moment(String(value)).format('DD-MM-YYYY, hh:mm a')
  }
});

// Get Country ISO code
Vue.filter('getISOCode', function(value) {
  if (value) {
    const { countryISO2Code = 'KE' } = Countries.find(obj => {
        return obj.countryName === this.authUser.country
    })
    return countryISO2Code.toLowerCase()
  }
});

// Time left
Vue.filter('timeLeft', function(value) {
  if (value) {
    let duration = moment.duration(value * 1000);
    let timeLeft = ''
    if (duration.asDays() > 0) { timeLeft = timeLeft + Math.floor(duration.asDays()) + ' days ' }
    if (duration.asHours() > 0) { timeLeft = timeLeft + Math.floor(duration.asHours()) % 24 + ' hours ' }
    if (duration.asMinutes() > 0) { timeLeft = timeLeft + Math.floor(duration.asMinutes()) % 60 + ' mins ' }
    // if (duration.asSeconds() > 0) { timeLeft = timeLeft + Math.floor(duration.asSeconds()) % 60 + ' secs ' }
    return timeLeft
  }
});

// Format values by rounding off
Vue.filter('round', function (value) {
  if (!value) {
    value = 0
  }

  let decimals = 2

  value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)
  return value
})

// Format values by commas
Vue.filter('commas', function (value) {
  value = value.toLocaleString()
  return value
})

// Format values to percentage
Vue.filter('percentage', function (value) {
  if (!value) {
    value = 0
  }

  let decimals = 2

  value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)
  value = value + '%'
  return value
})

Vue.filter('nameInRoute', function (value, name) {
  if (value === null) {
    return false
  }
  value = value.toLowerCase();
  let result = value.includes(name);
  return result
})

Vue.filter('getFileName', function (value) {
  if (value === null) {
    return value
  }
  console.log(value.split("/").pop().split("?")[0]);
  return value.split("/").pop().split("?")[0]
})

Vue.filter('truncate', function(data,num){
  return data.split("").slice(0, num).join("");
})

Vue.config.productionTip = false

// Sentry.init({
//   Vue,
//   dsn: "https://352919ec5b20d2ceed4941bf7e8d5a6d@o4508278642049024.ingest.us.sentry.io/4508278643687424",
//   integrations: [
//     Sentry.browserTracingIntegration({ router }),
//     Sentry.replayIntegration(),
//   ],
//   // Tracing
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", /^https:\/\/api\.tendersure\.co.ke/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

new Vue({
  store,
  router,
  render: h => h(App),
  i18n
}).$mount('#app')
